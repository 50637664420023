import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const StackedColumnChart = ({ dataColors, periodData, regionalCategories }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors)
  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: 0,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "25%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Borno",
        "Lagos",
        "Imo",
        "Abia",
        "Kano",
        "Kwara",
        "Oyo",
        "Delta",
        "Yobe",
        "Kogi",
        "Niger",
        "FCT",
        "Sokoto",
        "Plateau",
        "Taraba",
        "Bauchi",
        "Enugu",
        "Osun",
      ],
      // categories: regionalCategories,
      labels: {
        show: true,
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
      show: false,
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        type="bar"
        height="500"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
