import React from "react"
import { Col, Card, CardBody, CardTitle } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const BarChart2 = ({ dataColors }) => {
  const spineareaChartColors = getChartColorsArray(dataColors)

  const series = [
    {
      data: [380, 430],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: spineareaChartColors,
    grid: {
      borderColor: ["#f1f1f1", "#000000"],
    },
    xaxis: {
      categories: [
        "Approved",
        "Rejected"
      ],
    },
  }

  return (
    <React.Fragment>
      <Col xl="12">
      <Card style={{ height: 300 }}>
          <CardBody>
            <CardTitle className="mb-4">Approved and rejected applications statistics</CardTitle>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="150"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default BarChart2
