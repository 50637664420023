import { post } from "../services";

export const AUTH = {
  userLogin: async (loginDto) => {
    console.log("loginDto :: " + loginDto);
    let data = await post(`auth/login`, loginDto);
    console.log("userLogin response :: " + data)
    return data;
  },
  forgotPwd: async (loginDto) => {
    console.log("forgot password dto :: " + loginDto);
    let data = await post(`auth/forgot-password`, loginDto);

    return data;
  },
  validateOtp: async (loginDto) => {
    console.log("validate otp dto :: " + loginDto);
    let data = await post(`auth/verify-admin-otp`, loginDto);

    return data;
  },
  resetPwd: async (loginDto) => {
    console.log("reset password otp dto :: " + loginDto);
    let data = await post(`auth/reset-password`, loginDto);

    return data;
  },
};
