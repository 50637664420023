import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import Swal from "sweetalert2"
import { useMutation } from "react-query"
import { GENERAL } from "api/general"
// users
import logo from "../../../assets/images/coat_of_arms.png"

const ProfileMenu = props => {
  const [show, setShow] = useState(false)
  const [showPassword, setshowPassword] = useState(false)
  const [showPassword2, setshowPassword2] = useState(false)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [userRole, setUserRole] = useState(
    JSON.parse(localStorage.getItem("userRole"))
  )
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const handleChangePwdFn = () => {
    if (newPassword == "" && currentPassword == "") {
      Swal.fire("Process Failed!", "Provide a valid password.", "error")
    }
    if (currentPassword == "") {
      Swal.fire("Process Failed!", "Current password is required.", "error")
    }
    if (currentPassword !== "" && newPassword == "") {
      Swal.fire("Process Failed!", "New password is required.", "error")
    }

    if (currentPassword !== "" && newPassword !== "") {
      const changedPwdDto = {
        current: currentPassword,
        password: newPassword,
        confirm: newPassword,
      }

      Swal.fire({
        title: "Changing password...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      changePwdFn.mutate(changedPwdDto)
    }
  }

  const changePwdFn = useMutation({
    mutationFn: payload => {
      return GENERAL.changePwdFn(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("change pwd response ::", res)
      if (res?.data?.statusCode == 422) {
        Swal.fire("Process Failed!", res?.data?.message?.join(", "), "error")
        showModal()
        console.log("createUserDto :: ", createUserDto)
      }
      if (res?.data?.palliative?.statusCode == 400) {
        Swal.fire("Process Failed!", res?.data?.palliative?.message, "error")
        showModal()
        console.log("createUserDto :: ", createUserDto)
      }
      if (res?.data?.palliative?.statusCode == 200) {
        Swal.fire(
          "Process Successful!",
          res?.data?.palliative?.message,
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            closeModal()
          }
        })
      }
    },
  })

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={logo}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {userRole === "SUPER_ADMIN" && (
            <DropdownItem tag="a" href="/manage-users">
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("ACCOUNT MANAGEMENT")}{" "}
            </DropdownItem>
          )}
          <DropdownItem tag="a" href="#" onClick={showModal}>
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("CHANGE PASSWORD")}
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("LOGOUT")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Modal size="lg" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div className="">
                <h5>Change Password</h5>
              </div>
            </Col>
            <Form>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="newPassword">
                      Current Password <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <InputGroup>
                        <Input
                          type={`${showPassword2 ? "text" : "password"}`}
                          placeholder="Enter Current Password"
                          name="currentPassword"
                          value={currentPassword}
                          onChange={evt => setCurrentPassword(evt.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i
                              onClick={() => setshowPassword2(!showPassword2)}
                              className={`mdi mdi-${
                                !showPassword2 ? "eye-off" : "eye"
                              }-outline`}
                            />
                          </span>
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="newPassword">
                      New Password <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <InputGroup>
                        <Input
                          type={`${showPassword ? "text" : "password"}`}
                          placeholder="Enter New Password"
                          name="newPassword"
                          value={newPassword}
                          onChange={evt => setNewPassword(evt.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i
                              onClick={() => setshowPassword(!showPassword)}
                              className={`mdi mdi-${
                                !showPassword ? "eye-off" : "eye"
                              }-outline`}
                            />
                          </span>
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
          <Row>
            <Col xl={6}>
              <button
                type="submit"
                className="btn btn-info w-100"
                onClick={handleChangePwdFn}
              >
                CHANGE PASSWORD
              </button>
            </Col>

            <Col xl={6}>
              <button
                type="submit"
                style={{ marginLeft: 9 }}
                className="btn btn-danger w-100"
                onClick={() => closeModal()}
              >
                CANCEL
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
