import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { useQuery } from "react-query"
import WelcomeComp from "./WelcomeComp"
import PieChart2 from "./PieChart2"
import BarChart2 from "./BarChart2"
import LatestTranaction from "./LatestTranaction"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"

import { GENERAL } from "api/general"
import { withTranslation } from "react-i18next"
import LatestTranactionNimc from "./LatestTranactionNimc"

const NimcStaffDashboard = props => {
  const [dashboardStats, setDashboardStats] = useState(null);

  const getDashboardStatisticsNimc = useQuery({
    queryKey: ["getDashboardStatisticsNimc"],
    queryFn: () => {
      return GENERAL.getDashboardStatisticsNimc("goal")
    },
    onSuccess: async res => {
      console.log("this is the dashboard statistics ::", res)
      // const fixedWallets = res.result
      setDashboardStats(res)
    },
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/total-agent-registrations-by-state"}> */}
                      <Link>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Total Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalApplicants || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/approved-agents-registration"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Approved Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalApprovedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Link to={"/approved-nimc-supervisors-by-state"}>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                Approved NIMC Supervisors
                              </p>
                              <h4 className="mb-0">
                                {dashboardStats?.totalApprovedNimcSupervisors || 0}
                              </h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={"bx bx-copy-alt font-size-24"}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col> */}
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link
                        to={
                          "/view-pending-agent-registrations-by-lga-nimc-staff"
                        }
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Pending Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalPendingAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link
                        to={
                          "/view-declined-agent-registrations-by-lga-nimc-staff"
                        }
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Declined Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalDeclinedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-rejected-agent-registrations"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Rejected Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalRejectedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <PieChart2 dataColors='["--bs-primary", "--bs-success"]' />
            </Col>
            <Col xl={6}>
              <BarChart2 dataColors='["--bs-primary", "--bs-success"]' />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestTranactionNimc />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NimcStaffDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(NimcStaffDashboard)
