import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useTable, useAsyncDebounce } from "react-table"
import { useMutation } from "react-query"
import { GENERAL } from "../../../api/general"
import { useParams } from "react-router"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"

function GlobalFilter() {
  const count = 0
  const [value, setValue] = React.useState("")
  const [filter, setFiilter] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

const ViewTrainingRecordsByLga = props => {
  const params = useParams()
  const [page, setPage] = useState(1)
  const [apiPageSize, setApiPageSize] = useState(10)
  const [data, setRecords] = useState([])

  const count = 0
  const [value, setValue] = React.useState("")
  const [filter, setFiilter] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const handleSearchFn = () => {
    console.log("this is the search keyword :: ", filter, value)
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const pageDto = {
      lga_id: params?.id,
      searchKey: filter ?? "",
      page: page,
      pageSize: apiPageSize,
    }
    getTrainingRecordsByLgaId.mutate(pageDto)
  }

  useEffect(() => {
    console.log("this is the page size :: ", page)
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const pageDto = {
      lga_id: params?.id,
      searchKey: "",
      page: page,
      pageSize: apiPageSize,
    }
    getTrainingRecordsByLgaId.mutate(pageDto)
  }, [page, apiPageSize])

  const gotoPreviousPage = () => {
    console.log("fetching next page...")
    setPage(page - 1)
  }
  const gotoNextPage = () => {
    console.log("fetching next page...")
    setPage(page + 1)
  }

  const getTrainingRecordsByLgaId = useMutation({
    mutationFn: payload => {
      return GENERAL.getTrainingRecordsByLgaId(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("approved records ::", res.data.palliative)
      if (res?.data?.palliative) {
        setRecords(res.data.palliative)
      }
    },
  })
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const isGlobalFilter = true
  const customPageSize = 6

  const columns = useMemo(
    () => [
      {
        Header: "Application ID",
        accessor: "npc_id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <BoldFormatText {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_resid",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_resid",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "No. Absent",
        accessor: "absent_count",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "No. Present",
        accessor: "present_count",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const BoldFormatText = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FormatText = cell => {
    return cell.value ? cell.value : ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("View Training Records")}
              breadcrumbItem={props.t("View Training Records")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">All Agents</div>
              <Fragment>
                <Row className="mb-2">
                  {/* {isGlobalFilter && <GlobalFilter />} */}
                  <React.Fragment>
                    <Col md={6}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => {
                                setValue(e.target.value)
                                onChange(e.target.value)
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${count} records...`}
                              value={value || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </React.Fragment>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={gotoPreviousPage}
                        disabled={page}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page <strong>{page}</strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={gotoNextPage}>
                        {">"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewTrainingRecordsByLga)
