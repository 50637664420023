import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  Table,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { useQuery, useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "api/general"
//i18n
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import dayjs from "dayjs"

const UserList = props => {
  const [page, setPage] = useState(1)
  const [apiPageSize, setApiPageSize] = useState(10)
  const [data, setRecords] = useState([])

  const [nin, setNin] = useState("")
  const [showPassword, setshowPassword] = useState(false)
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [states, setStates] = useState([])
  const [lgas, setLgas] = useState([])

  const [selectedState, setSelectedState] = useState("")
  const [selectedLga, setSelectedLga] = useState("")
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [show, setShow] = useState(false)
  const [isNinVerified, setIsNinVerified] = useState(false)
  const [isEmailErr, setIsEmailErr] = useState(true)
  const [isPasswordErr, setIsPasswordErr] = useState(true)
  const [isStateErr, setIsStateErr] = useState(true)
  const [isLgaErr, setIsLgaErr] = useState(true)
  const [isRoleErr, setIsRoleErr] = useState(true)
  const [isRegionErr, setIsRegionErr] = useState(true)
  const navigate = useNavigate()
  //meta title

  useEffect(() => {
    getStates
  }, [])

  const count = 0
  const [value, setValue] = React.useState("")
  const [filter, setFiilter] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const handleSearchFn = () => {
    console.log("this is the search keyword :: ", filter, value)
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const pageDto = {
      searchKey: filter ?? "",
      page: 1,
      pageSize: 20,
    }
    getAdminList.mutate(pageDto)
  }

  useEffect(() => {
    console.log("this is the page size :: ", page)
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const pageDto = {
      searchKey: "",
      page: page,
      pageSize: apiPageSize,
    }
    getAdminList.mutate(pageDto)
  }, [page, apiPageSize])

  const gotoPreviousPage = () => {
    console.log("fetching next page...")
    setPage(page - 1)
  }
  const gotoNextPage = () => {
    console.log("fetching next page...")
    setPage(page + 1)
  }

  const getAdminList = useMutation({
    mutationFn: payload => {
      return GENERAL.getAdminListFn(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("admin list response ::", res)
      if (res.status === 201) {
        setRecords(res.data.palliative)
      }
    },
  })

  const getStates = useQuery({
    queryKey: ["getStates"],
    queryFn: () => {
      return GENERAL.getStates()
    },
    onSuccess: async res => {
      console.log("get all statest response ::", res)
      if (res?.palliative) {
        setStates(res.palliative)
      }
    },
  })

  const getLgas = useMutation({
    mutationFn: payload => {
      return GENERAL.getLgas(payload.state_id)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("these are the lgas ::", res)
      if (res?.palliative) {
        setLgas(res.palliative)
      }
    },
  })

  const createUser = useMutation({
    mutationFn: payload => {
      return GENERAL.createUser(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("create user response ::", res)
      // const fixedWallets = res.result
      if (res.status == 422) {
        Swal.fire("Process Failed!", res.data.message.join(", "), "error").then(
          res => {
            if (res.isConfirmed) {
              showModal()
            }
          }
        )
        // showModal()
        // console.log("createUserDto :: ", createUserDto)
      }
      if (res.status == 403) {
        Swal.fire("Process Failed!", res.data.message, "error").then(res => {
          if (res.isConfirmed) {
            showModal()
          }
        })

        // console.log("createUserDto :: ", createUserDto)
      }
      if (res.status == 201) {
        Swal.fire("Process Successful!", res.data.message, "success").then(
          res => {
            if (res.isConfirmed) {
              Swal.fire({
                title: "Fetching records...",
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading(Swal.getDenyButton())
                },
              })
              const pageDto = {
                searchKey: "",
                page: page,
                pageSize: apiPageSize,
              }
              getAdminList.mutate(pageDto)
            }
          }
        )
      }
    },
  })

  const handleSelectState = evt => {
    console.log("evt :: ", evt.target.value)
    setSelectedState(evt.target.value)
    if (selectedRole !== "STATE_SUPERVISOR") {
      Swal.fire({
        title: "Fetching LGAs...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      const getLgaDto = {
        state_id: evt.target.value,
      }
      console.log("getLgaDto :: ", getLgaDto)
      getLgas.mutate(getLgaDto)
    }
  }

  const handleSelectLga = evt => {
    console.log("evt :: ", evt.target.value)
    setSelectedLga(evt.target.value)
  }

  const handleSubmit = () => {
    // closeModal()
    console.log(
      "selectedRole :; ",
      selectedRole,
      "selectedLga :: ",
      selectedLga
    )
    if (email) {
      setIsEmailErr(false)
    } else {
      return Swal.fire("Process Failed!", "Email is required.", "error")
    }
    if (password) {
      setIsPasswordErr(false)
    } else {
      return Swal.fire("Process Failed!", "Password is required.", "error")
    }
    if (selectedRole != null) {
      setIsRoleErr(false)
    } else {
      return Swal.fire("Process Failed!", "Role is required.", "error")
    }
    console.log("this is the selectedRegion :: ", selectedRegion)
    if (
      (selectedRegion != null && selectedRole == "REGIONAL_SUPERVISOR") ||
      (selectedRegion == null && selectedRole !== "REGIONAL_SUPERVISOR")
    ) {
      setIsRegionErr(false)
    } else {
      return Swal.fire("Process Failed!", "Region is required.", "error")
    }

    // state check
    if (selectedRole != null) {
      if (
        (selectedRole === "NIMC_STAFF" ||
          selectedRole === "STATE_SUPERVISOR" ||
          selectedRole === "LGA_SUPERVISOR") &&
        selectedState !== ""
      ) {
        setIsStateErr(false)
      } else if (
        selectedRole === "SUPER_ADMIN" ||
        selectedRole === "REGIONAL_SUPERVISOR"
      ) {
        setIsStateErr(false)
      } else {
        Swal.fire("Process Failed!", "State is required.", "error")
      }

      // lga check
      if (selectedState !== "") {
        if (
          (selectedRole === "NIMC_STAFF" ||
            selectedRole === "STATE_SUPERVISOR" ||
            selectedRole === "LGA_SUPERVISOR") &&
          selectedLga !== ""
        ) {
          setIsLgaErr(false)
        } else if (
          selectedRole === "SUPER_ADMIN" ||
          selectedRole === "REGIONAL_SUPERVISOR" ||
          selectedRole === "STATE_SUPERVISOR"
        ) {
          setIsLgaErr(false)
        } else {
          Swal.fire("Process Failed!", "LGA is required.", "error")
        }
      }
    }

    // email check
    if (selectedRole != null) {
      // setEmail("")
      if (email != null && email !== "") {
        if (selectedRole === "NIMC_STAFF") {
          if (!email.toLocaleLowerCase().includes("@nimc.gov.ng")) {
            Swal.fire("Process Failed!", "Provide a valid NIMC email", "error")
            return
          }
        } else if (
          selectedRole === "SUPER_ADMIN" ||
          selectedRole === "REGIONAL_SUPERVISOR" ||
          selectedRole === "STATE_SUPERVISOR" ||
          selectedRole === "LGA_SUPERVISOR"
        ) {
          if (
            !email.toLocaleLowerCase().includes("@") ||
            email.toLocaleLowerCase().includes("@nimc.gov.ng")
          ) {
            Swal.fire("Process Failed!", "Email is not valid", "error")
            return
          }
        }
      }
    } else {
      setIsRoleErr(false)
    }

    if (
      (selectedRole != null &&
        selectedRole === "SUPER_ADMIN" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr) ||
      (selectedRole != null &&
        selectedRole === "STATE_SUPERVISOR" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isStateErr) ||
      (selectedRole != null &&
        selectedRole === "REGIONAL_SUPERVISOR" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isRegionErr) ||
      (selectedRole != null &&
        selectedRole === "LGA_SUPERVISOR" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isStateErr &&
        !isLgaErr) ||
      (selectedRole != null &&
        selectedRole === "NIMC_STAFF" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isStateErr &&
        !isLgaErr)
    ) {
      closeModal()
      const createUserDto = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        password: password,
        role: selectedRole, // or NIMC_STAFF
        state: selectedState,
        nin: nin,
        lga: selectedLga,
        // zone: selectedRegion
        zone: selectedRole === "REGIONAL_SUPERVISOR" ? selectedRegion : "0"
      }

      Swal.fire({
        title: "Creating user...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      console.log("createUserDto :: ", createUserDto)
      createUser.mutate(createUserDto)
    } else {
      console.log("we are here")
    }
  }

  document.title = "ManageUsers - eRecruitement"
  const customPageSize = 6

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "index",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatIndex {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "role",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "NIN",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
    ],
    []
  )

  const FormatIndex = cell => {
    console.log("row :: ", cell)
    const index = +cell?.row?.id + 1
    return index
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const BoldFormatText = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FormatText = cell => {
    return cell.value ? cell.value : ""
  }

  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setIsNinVerified(false)
    setEmail("")
    setShow(false)
  }

  const handleVerifyNinFn = () => {
    Swal.fire({
      title: "Verifying NIN...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const verifyNinDto = {
      nin: nin,
    }
    console.log("verifyNinDto :: ", verifyNinDto)
    verifyNinFn.mutate(verifyNinDto)
  }

  const verifyNinFn = useMutation({
    mutationFn: payload => {
      return GENERAL.ninVerifyFn(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("verify nin response ::", res)
      if (res?.data?.palliative?.statusCode == 200) {
        setFirstname(res?.data?.palliative?.firstname)
        setLastname(res?.data?.palliative?.lastname)
        setPhone(res?.data?.palliative?.phone)
        setIsNinVerified(true)
      }
      if (res?.data?.palliative?.statusCode == 500) {
        Swal.fire("Process Failed!", res?.data?.palliative?.msg, "error")
      }
    },
  })

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5>Create User</h5>

                <div onClick={closeModal}>
                  <i
                    className="bx bx-x"
                    style={{ fontSize: 24, cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </Col>
            <Form>
              {!isNinVerified && (
                <Row>
                  <Col md={9}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        NIN <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="number"
                          placeholder="Enter NIN"
                          maxLength={11}
                          name="firstname"
                          onChange={evt => setNin(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="text-center mt-3">
                      {!verifyNinFn.isLoading && (
                        <button
                          type="submit"
                          className="btn btn-primary w-80 ml-5"
                          onClick={handleVerifyNinFn}
                        >
                          VERIFY NIN
                        </button>
                      )}
                      {verifyNinFn.isLoading && (
                        <button
                          type="submit"
                          className="btn btn-primary w-80 ml-5"
                        >
                          VERIFYING NIN
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              {isNinVerified && (
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          placeholder="Enter First Name"
                          name="firstname"
                          value={firstname}
                          disabled
                          onChange={evt => setFirstname(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          name="lastname"
                          value={lastname}
                          disabled
                          placeholder="Enter Last Name"
                          onChange={evt => setLastname(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Phone Number <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          name="phone"
                          value={phone}
                          disabled
                          placeholder="Enter Phone Number"
                          onChange={evt => setPhone(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          name="email"
                          value={email}
                          required={true}
                          placeholder="Enter Email"
                          // onChange={evt => handleSetEmail(evt.target.value)}
                          onChange={evt => setEmail(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-password-Input">
                        Role <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <select
                          onChange={evt => setSelectedRole(evt.target.value)}
                          className="form-control"
                        >
                          <option>Select</option>
                          <option value="SUPER_ADMIN">SUPER ADMIN</option>
                          <option value="NIMC_STAFF">NIMC STAFF</option>
                          <option value="REGIONAL_SUPERVISOR">
                            REGIONAL COORDINATOR
                          </option>
                          <option value="STATE_SUPERVISOR">
                            STATE COORDINATOR
                          </option>
                          <option value="LGA_SUPERVISOR">
                            LGA COORDINATOR
                          </option>
                        </select>
                      </div>
                    </div>
                  </Col>

                  {selectedRole && selectedRole === "REGIONAL_SUPERVISOR" && (
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">
                          Region <span className="text-danger">*</span>
                        </Label>
                        <div className="col-md-12">
                          <select
                            onChange={evt =>
                              setSelectedRegion(evt.target.value)
                            }
                            className="form-control"
                          >
                            <option>Select</option>
                            <option value="1">NORTH WEST</option>
                            <option value="2">NORTH EAST</option>
                            <option value="3">NORTH CENTRAL</option>
                            <option value="4">SOUTH EAST</option>
                            <option value="5">SOUTH SOUTH</option>
                            <option value="6">SOUTH WEST</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                  )}

                  {((selectedRole && selectedRole === "NIMC_STAFF") ||
                    (selectedRole && selectedRole === "STATE_SUPERVISOR") ||
                    (selectedRole && selectedRole === "LGA_SUPERVISOR")) && (
                    <>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            State <span className="text-danger">*</span>
                          </Label>
                          <div className="col-md-12">
                            <select
                              onChange={handleSelectState}
                              className="form-control"
                            >
                              <option>Select</option>
                              {states?.map(state => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                  {((selectedRole && selectedRole === "NIMC_STAFF") ||
                    (selectedRole && selectedRole === "LGA_SUPERVISOR")) && (
                    <>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            Lga <span className="text-danger">*</span>
                          </Label>
                          <div className="col-md-12">
                            <select
                              onChange={handleSelectLga}
                              className="form-control"
                            >
                              <option>Select</option>
                              {lgas?.map(lga => (
                                <option key={lga.id} value={lga.id}>
                                  {lga.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Password <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <InputGroup>
                          <Input
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="********"
                            name="password"
                            onChange={evt => setPassword(evt.target.value)}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i
                                onClick={() => setshowPassword(!showPassword)}
                                className={`mdi mdi-${
                                  !showPassword ? "eye-off" : "eye"
                                }-outline`}
                              />
                            </span>
                          </div>
                        </InputGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </Row>
          {isNinVerified && (
            <Row>
              <div className="text-center">
                <button
                  style={{ marginRight: 10 }}
                  className="btn btn-danger w-80 mr-2"
                  onClick={() => closeModal()}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-80 ml-5"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </Row>
          )}
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Account Management")}
              breadcrumbItem={props.t("Manage Users")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Account Management</div>
              <Fragment>
                <Row className="mb-2">
                  <Col md={10}>
                    <React.Fragment>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => {
                                setValue(e.target.value)
                                onChange(e.target.value)
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${count} records...`}
                              value={value || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  </Col>

                  <Col sm="2">
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn mb-2 me-2"
                        onClick={() => showModal()}
                      >
                        Create User
                      </Button>
                    </div>
                  </Col>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={gotoPreviousPage}
                        disabled={page == 1}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page <strong>{page}</strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={gotoNextPage}>
                        {">"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UserList)
