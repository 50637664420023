import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import WelcomeComp from "./WelcomeComp"
import BarChart from "./BarChart"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StackedColumnChart from "./StackedColumnChart"
import {
  regionalDataBarChart,
  regionalDataPieChart,
  stateCategories,
  stateData,
} from "common/data/dashboard"
import RegistrationByGender from "./RegistrationByGender"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"

const RegionalSupervisorDashboard = () => {
  useEffect(() => {
    getDashboardStatsRegional
  }, [])

  const [dashboardStats, setDashboardStats] = useState(null)

  const getDashboardStatsRegional = useQuery({
    queryKey: ["getDashboardStatsRegional"],
    queryFn: () => {
      return GENERAL.getDashboardStatsRegional()
    },
    onSuccess: async res => {
      console.log("Regional dashboard statistics ::", res)
      // const fixedWallets = res.result
      setDashboardStats(res)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Dashboard"
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <RegistrationByGender
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={regionalDataPieChart}
              />
              <BarChart
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={regionalDataBarChart}
              />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalApplicants || 0}
                            {/* {234 - 15} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-approved-agent-registrations-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Approved Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalApprovedAgents || 0}
                            {/* {234 - 45} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-approved-nimc-supervisors-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Approved NIMC Supervisors
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalApprovedNimcSupervisors || 0}
                            {/* {234 - 18} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-pending-agent-registrations-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Pending Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalPendingAgents || 0}
                            {/* {234 - 74} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-declined-agent-registrations-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Declined Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalDeclinedAgents || 0}
                            {/* {234 - 25} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-rejected-agent-registrations"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Rejected Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalRejectedAgents || 0}
                            {/* {234 - 50} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      States with the highest registration agents
                    </h4>
                  </div>
                  <StackedColumnChart
                    periodData={stateData}
                    regionalCategories={stateCategories}
                    dataColors='["--bs-primary", "--bs-primary", "--bs-primary"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegionalSupervisorDashboard
