import { get, post } from "../services"
import { getKey } from "../helpers"

export const GENERAL = {
  getDashboardStatistics: async () => {
    console.log("calling  general service")
    let token = await getKey("token")
    console.log("this is the user token :: ", token)
    let data = await get(`admin/get-dashboard-stats`)

    return data
  },
  getDashboardStatsLga: async () => {
    let data = await get(`admin/get-dashboard-stats-per-lga`)
    return data
  },
  getDashboardStatsState: async () => {
    let data = await get(`admin/get-dashboard-stats-per-state`)
    return data
  },
  getDashboardStatsRegional: async () => {
    let data = await get(`admin/get-dashboard-stats-per-region`)
    return data
  },
  getDashboardStatisticsNimc: async () => {
    let data = await get(`admin/get-dashboard-stats-per-lga`)

    return data
  },
  getApprovedAgents: async () => {
    console.log("calling approved agents endpoint")
    let data = await get(`admin/get-approve-agent-states`)

    return data
  },
  getApprovedAgentsByStateId: async id => {
    let data = await post(`admin/get-approve-agent-lga`, { state_id: +id })

    return data
  },
  getApprovedNimcRecordsByState: async () => {
    let data = await get(`admin/get-approve-nimc-states`)

    return data
  },
  getApprovedNimcRecordsByStateId: async id => {
    let data = await post(`admin/get-approve-nimc-lga`, { state_id: +id })

    return data
  },
  getRecordsByStateId: async payload => {
    let data = await post(`admin/get-state-list`, payload)

    return data
  },
  getRecordsByLgaId: async payload => {
    console.log("get approved records by lga dto :: ", payload)
    let data = await post(`admin/get-lga-list`, payload)

    return data
  },
  getApprovedAgentListPerLga: async payload => {
    console.log("get approved records by lga dto :: ", payload)
    let data = await post(`admin/get-approved-agent-list-per-lga`, payload)

    return data
  },
  getRecordByNpcId: async payload => {
    console.log("get-single-agent dto :: ", payload)
    let data = await post(`admin/get-single-agent`, payload)

    return data
  },
  getStates: async () => {
    console.log("getting all states")
    let data = await get(`resources/states`)

    return data
  },
  getLgas: async state_id => {
    console.log("get state param :: ", state_id)
    let data = await get(`resources/lga?stateId=${state_id}`)

    return data
  },
  createUser: async payload => {
    console.log("create user dto :: ", payload)
    let data = await post(`auth/create-user`, payload)

    return data
  },
  generateCode: async payload => {
    console.log("generate code dto :: ", payload)
    let data = await post(`admin/generate-agent-codes`, payload)

    return data
  },
  retriveGeneratedCodes: async payload => {
    console.log("retrive generated code dto :: ", payload)
    let data = await post(`admin/retrive-generated-lga`, payload)

    return data
  },
  retriveGeneratedCodesByLga: async payload => {
    console.log("retrive codes by lga dto :: ", payload)
    let data = await post(`admin/retrive-codes-per-lga`, payload)

    return data
  },
  markAttendance: async payload => {
    console.log("mark attendance dto :: ", payload)
    let data = await post(`admin/mark-attendance`, payload)

    return data
  },
  deleteAgentFn: async payload => {
    console.log("delete-agent dto :: ", payload)
    let data = await post(`admin/delete-agent`, payload)

    return data
  },
  ninVerifyFn: async payload => {
    console.log("nin-verify dto :: ", payload)
    let data = await post(`nin/verify`, payload)

    return data
  },
  changePwdFn: async payload => {
    console.log("change pwd dto :: ", payload)
    let data = await post(`admin/change-password`, payload)

    return data
  },
  getAdminListFn: async payload => {
    console.log("admin list dto :: ", payload)
    let data = await post(`admin/get-admins-list`, payload)

    return data
  },
  getTrainingRecordsByStateId: async payload => {
    console.log("get attendance list by state dto :: ", payload)
    let data = await post(`admin/get-attendance-list`, payload)

    return data
  },
  getTrainingRecordsByLgaId: async payload => {
    console.log("get attendance list by lga dto :: ", payload)
    let data = await post(`admin/get-attendance-list-lga`, payload)

    return data
  },
  getApprovedRecordsList: async payload => {
    console.log("get approved record list dto :: ", payload)
    let data = await post(`admin/get-approved-agent-list`, payload)

    return data
  },
  getApprovedNimcRecordsList: async payload => {
    console.log("get approved record list dto :: ", payload)
    let data = await post(`admin/get-approved-nimc-list`, payload)

    return data
  },
  getPendingRecordsList: async payload => {
    console.log("get pending record list dto :: ", payload)
    let data = await post(`admin/get-pending-list`, payload)

    return data
  },
  getPendingRecordsListNimc: async payload => {
    console.log("get pending record list dto :: ", payload)
    let data = await post(`admin/get-pending-list-per-lga`, payload)

    return data
  },
  getDeclinedRecordsList: async payload => {
    console.log("get pending record list dto :: ", payload)
    let data = await post(`admin/get-declined-agent-list`, payload)

    return data
  },
  getDeclinedRecordsListNimc: async payload => {
    console.log("get pending record list dto :: ", payload)
    let data = await post(`admin/get-declined-agent-list-per-lga`, payload)

    return data
  },
  getRejectedRecordsList: async payload => {
    console.log("get pending record list dto :: ", payload)
    let data = await post(`admin/get-rejected-list`, payload)

    return data
  },
  searchAgent: async payload => {
    console.log("search agent dto :: ", payload)
    let data = await post(`admin/search-agent`, payload)

    return data
  },
}
