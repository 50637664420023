import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import dayjs from "dayjs"
import jsPDF from "jspdf"
import "jspdf-autotable"
import * as xlsx from "xlsx"
import saveAs from "file-saver"
import clipboardCopy from "clipboard-copy"

import { Button, Card, CardBody, CardTitle } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"
import { useNavigate } from "react-router-dom"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  Index,
} from "./LatestTranactionCol"

import TableContainer from "../../components/Common/TableContainer"

const LatestTranactionNimc = props => {
  const navigate = useNavigate()
  const [dashboardStats, setDashboardStats] = useState(null)
  const getDataPlans = useQuery({
    queryKey: ["getDataPlans"],
    queryFn: () => {
      return GENERAL.getDashboardStatisticsNimc("goal")
    },
    onSuccess: async res => {
      console.log("this is the dashboard statistics ::", res)
      // const fixedWallets = res.result
      setDashboardStats(res)
    },
  })
  console.log("lastest transaction props :: ", props)
  const title = "Latest Tranactions"

  const exportExcel = () => {
    const worksheet = xlsx.utils.json_to_sheet(products)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    })
    saveAsExcelFile(excelBuffer, title)
  }

  const saveAsExcelFile = (buffer, fileName) => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    let EXCEL_EXTENSION = ".xlsx"
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    })
    saveAs(data, fileName + "export" + new Date().getTime() + EXCEL_EXTENSION)
  }

  // const copyToClipboard = () => {
  //   // Get the text you want to copy
  //   let tableContent = "";
  //   // Iterate through the table fields and build the content
  //   products.forEach((product) => {
  //     const rowContent = visibleColumns
  //       .map((col) => product[col.field]) // Map each column to its value in the product
  //       .join(", "); // Join the column values with a separator (e.g., a comma or anything you want)

  //     // Add the formatted row content to the table content
  //     tableContent += ${rowContent}\n;
  //   });

  //   // Use clipboard-copy callback to copy the text to the clipboard
  //   clipboardCopy(tableContent)
  //     .then(() => {
  //       // Handle success, e.g., show a message to the user
  //       setOpen(true);
  //       setTimeout(() => {
  //         setOpen(false);
  //       }, 1500);
  //     })
  //     .catch((err) => {
  //       // Handle errors, e.g., show an error message
  //       alert("Copy to clipboard failed");
  //     });
  // };

  const [modal1, setModal1] = useState(false)

  const toggleViewModal = () => setModal1(!modal1)

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   accessor: "id",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <Index {...cellProps} />
      //   },
      // },
      {
        Header: "Application ID",
        accessor: "npc_id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Last Name.",
        accessor: "last_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_resid",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_resid",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => gotoProfile(cellProps)}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? '').format('DD-MM-YYYY') : ""
  }

  const gotoProfile = (evt) => {
    const npc_id = evt?.row?.values?.npc_id;
    if (!npc_id) {
      return Swal.fire('Process failed', 'User not found', 'error')
    }
    navigate(`/profile/${npc_id}`)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
        <CardTitle>Most Recent Registrations</CardTitle>
          {/* <div className="d-flex flex-wrap">
            <h4 className="card-title me-2">Most Recent Registration Agents</h4>
            <div className="ms-auto">
              <div className="toolbar d-flex flex-wrap gap-2 text-end">
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                >
                  COPY
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                >
                  EXCEL
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                >
                  PDF
                </button>{" "}
              </div>
            </div>
          </div> */}

          {dashboardStats?.latestApplications && (
              <TableContainer
                columns={columns}
                data={dashboardStats?.latestApplications}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={6}
              />
            )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

LatestTranactionNimc.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTranactionNimc)
