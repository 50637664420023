import React, { useMemo, Fragment } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useTable, useAsyncDebounce, usePagination } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

function GlobalFilter() {
  const count = 0
  const [value, setValue] = React.useState("")
  const [filter, setFiilter] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

const ViewRejectedAgentRegistrationsByLga = props => {
  const navigate = useNavigate()
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const data = [
    {
      id: 1,
      orderId: "AGENT-435-6789",
      billingName: "Daniel Miracle Kenneth",
      orderdate: "08165435566",
      total: "Abia",
      paymentStatus: "Aba North",
      dateCreated: "14-04-2023, 10:56",
    },
  ]
  const isGlobalFilter = true
  const customPageSizeOptions = true
  // const isAddUserList = true
  const customPageSize = 6

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <Index {...cellProps} />
        },
      },
      {
        Header: "Application ID",
        accessor: "orderId",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "billingName",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "total",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "paymentStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                gotoProfile()
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const gotoProfile = () => {
    navigate("/profile")
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  // const formateDate = (date, format) => {
  //   const dateFormat = format ? format : "DD MMM Y"
  //   const date1 = moment(new Date(date)).format(dateFormat)
  //   return date1
  // }
  // const toLowerCase1 = str => {
  //   return str === "" || str === undefined ? "" : str.toLowerCase()
  // }

  const Index = cell => {
    return cell.value ? cell.value : ""
  }

  const OrderId = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const BillingName = cell => {
    return cell.value ? cell.value : ""
  }

  const Date = cell => {
    return cell.value ? cell.value : ""
  }

  const Total = cell => {
    return cell.value ? cell.value : ""
  }

  const PaymentStatus = cell => {
    return cell.value ? cell.value : ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Rejected Agent Registrations")}
              breadcrumbItem={props.t("Rejected Agent Registrations By Lga")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">All Registration Agents</div>
              <Fragment>
                <Row className="mb-2">
                  {/* <Col md={customPageSizeOptions ? 2 : 1}>
                    <select
                      className="form-select"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col> */}

                  {isGlobalFilter && <GlobalFilter />}

                  {/* {isAddUserList && (
                    <Col sm="7">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="btn mb-2 me-2"
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Create New User
                        </Button>
                      </div>
                    </Col>
                  )} */}
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} 
                    </strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        {">"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewRejectedAgentRegistrationsByLga)
