import React, { useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import coat from "../../assets/images/coat_of_arms.png"

const WelcomeComp = () => {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  console.log("userInfo :: ", userInfo)
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary" style={{ marginBottom: 0 }}>
                  Welcome Back !
                </h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={coat}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              {/* <h5 className="font-size-15 text-truncate"> */}
              <h5 className="font-size-15">
                {userInfo.firstname} (
                {userInfo.role == "REGIONAL_SUPERVISOR"
                  ? "NORTH WEST"
                  : userInfo.role == "STATE_SUPERVISOR"
                  ? "KANO"
                  : ""}{" "}
                {String(userInfo.role).replace("_", " ")})
              </h5>
              <p className="text-muted mb-0 text-truncate">{userInfo.email}</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
